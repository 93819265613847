<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1">
          {{projectName}}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            2-Factor Authentication
          </b-card-title>
          <b-card-text class="mb-2">
            Please use your authenticator app and enter the 2FA authorization code
          </b-card-text>

          <!-- form -->
          <validation-observer ref="2faValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Authorization code"
                label-for="login-2fa"
              >
              <b-link @click="confirmReset2FA">
                    <small>Reset 2FA code?</small>
                  </b-link>
                <validation-provider
                  #default="{ errors }"
                  name="2FAcode"
                  rules="required"
                >
                  <b-form-input
                    ref="codeInput"
                    id="login-2fa"
                    v-model="authcode"
                    :state="errors.length > 0 ? false:null"
                    name="login-2fa"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              


              <b-button
                type="submit"
                variant="primary"
                block
                @click="login2fa"
              >
                Validate
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>

        <b-col v-if="new2fa"
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Renew 2 factor authorization
          </b-card-title>
          <b-card-text class="mb-2">
           Request a new 2FA
          </b-card-text>

          <!-- form -->
          <validation-observer ref="newPasswordValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-email-new-password">Email</label>
                  <b-link @click="newPassword=false">
                    <small>Back to 2FA</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email-new-password"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email-new-password"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>



              <b-button
                type="submit"
                variant="primary"
                block
                @click="validateNew2FA"
              >
                Submit
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>




      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      authcode: '',
      password: '',
      userEmail: '',
      new2fa: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {


    imgUrl() {
        this.sideImg = require('@/assets/images/logo/physiocheck_admin_logo.png')
        return this.sideImg
    },
  },
  methods: {
    confirmReset2FA() {
      if (confirm("Are you sure you want to reset the 2FA code?")) {
        this.submitNew2FA();
      }
    },
    
    async submitNew2FA() {
        const res = await this.callApi('post', '/login/renew2fa', {
            email:this.userEmail
        });
        if (res.data.result.success) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'New validation link sent',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push('/login');

        }
        
    },
    async login2fa() {
        const res = await this.callApi('post', '/login/login2fa', {
            code:this.authcode
        });
        console.log(res)
        if (res.data.login.login2fa) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '2FA validated!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push('/dashboard');
        }
        else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validation failed',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
            //alert("Wrong email / password")
            
        }
    },
    validationForm() {

      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
  mounted() {
    this.$refs.codeInput.focus();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
